import { Component, Inject, Vue, Watch } from "vue-property-decorator";
import { CONTAINER_TYPES } from "../../infrastructure/ioc/container-types";
import ServiceInterface from "@/vue/service/service-interface";
import Utils from "@/vue/infrastructure/utils/helpers";
import DfStore from "@/vue/domain/store/df-store";
import DfContent from "@/vue/domain/content/df-content";
import { Carousel, Slide } from "vue-carousel-variable-width";
import DfBrand from "@/vue/domain/brand/df-brand";
import DfPromotion from "@/vue/domain/promotion/df-promotion";
import Swal from "sweetalert2";

const DfSectionBannerComponent = () => Utils.externalComponent2("df-section-banner");
const DfSectionLinksComponent = () => Utils.externalComponent2("df-section-links");
const DfSectionPromotionsComponent = () => Utils.externalComponent2("df-section-promotions");
const DfSectionNewsComponent = () => Utils.externalComponent2("df-section-news");
const DfSectionRecipesComponent = () => Utils.externalComponent2("df-section-recipes");
const DfStoreCardComponent = () => Utils.externalComponent2("df-store-card");
const DfCampaignCardComponent = () => Utils.externalComponent2("df-campaign-card");
const DfHeaderMobileComponent = () => Utils.externalComponent2("df-header-mobile");

@Component({
  components: {
    DfLoadingMask: () => import(/* webpackChunkName: 'df-loading-mask' */ "@/vue/components/df-loading-mask/df-loading-mask.vue"),
    DfSectionBanner: DfSectionBannerComponent,
    DfHeaderMobile: DfHeaderMobileComponent,
    DfFooter: () => Utils.externalComponent2("df-footer"),
    DfStoreCard: DfStoreCardComponent,
    DfSectionPromotions: DfSectionPromotionsComponent,
    DfSectionNews: DfSectionNewsComponent,
    DfSectionRecipes: DfSectionRecipesComponent,
    DfSectionLinks: DfSectionLinksComponent,
    DfCampaignCard: DfCampaignCardComponent,
    Carousel,
    Slide,
  },
})
export default class DfPageHomeComponent extends Vue {
  @Inject(CONTAINER_TYPES.ServiceInterface)
  private service!: ServiceInterface;

  private loading = false;

  get currentStore(): DfStore {
    return this.$store.getters.currentStore;
  }

  get showPromotionsFlyer(): boolean {
    return this.$store.getters.promotions.filter((promotion: DfPromotion) => {
      return Utils.getPropertyValue(promotion, Utils.PROPERTY_PROMOTION_TYPE, "LIST", { key: "FLYER" }).key === "FLYER";
    }).length > 0;
  }

  get showPromotionsCatalog(): boolean {
    return this.$store.getters.promotions.filter((promotion: DfPromotion) => {
      return Utils.getPropertyValue(promotion, Utils.PROPERTY_PROMOTION_TYPE, "LIST", { key: "FLYER" }).key === "CATALOG";
    }).length > 0;
  }

  get showPromotionsMagazine(): boolean {
    return this.$store.getters.promotions.filter((promotion: DfPromotion) => {
      return Utils.getPropertyValue(promotion, Utils.PROPERTY_PROMOTION_TYPE, "LIST", { key: "FLYER" }).key === "MAGAZINE";
    }).length > 0;
  }

  get showNews(): boolean {
    return this.$store.getters.brandContents.filter((content: DfContent) => content.type.code === "NWS").length > 0;
  }

  get showRecipes(): boolean {
    return this.$store.getters.brandContents.filter((content: DfContent) => content.type.code === "RCP").length > 0;
  }

  get promotionsCount(): boolean {
    return this.$store.getters.promotions.length;
  }

  get banners(): Array<DfContent> {
    return this.$store.getters.brandContents.filter((content: DfContent) => content.type.code === "BAN");
  }

  get brand(): DfBrand {
    return this.$store.getters.brand;
  }

  get campaigns(): Array<DfContent> {
    const brandCampaigns: Array<DfContent> = this.$store.getters.brandContents.filter((content: DfContent) => content.type.code === "CMP");
    const storeCampaigns: Array<DfContent> = this.$store.getters.storeContents.filter((content: DfContent) => content.type.code === "CMP");
    return [...brandCampaigns, ...storeCampaigns].sort((contentA, contentB) => (contentA.priority <= contentB.priority ? 1 : -1));

  }

  get campaignsPerPage(): number {
    return Utils.getPropertyValue(this.brand, Utils.PROPERTY_CAMPAIGNS_PER_PAGE, `NUMBER`, 2);
  }

  get scrollbarHidden(): boolean {
    return this.$store.getters.scrollbarHidden;
  }

  get loginMail(): string {
    return this.$store.getters.loginMail;
  }

  get showLogin(): boolean {
    return this.$store.getters.showLogin;
  }

  private goToStorePage(store: DfStore) {
    this.$router.push({ name: "store", params: { storeAlias: store.alias } }).catch((err) => err);
  }

  private openMap() {
    window.open(`${Utils.GOOGLE_MAP_URL}${Utils.storeGoogleMapsCoordinates(this.currentStore)}`, "_blank");
  }
}
